import React from "react";

import footerLogo from '../images/footer-logo.svg';
import Menus from "./DrupalMenuFooter"
import { useStaticQuery, graphql } from "gatsby";

const { GATSBY_API_ENV = 'default' } = process.env;

export default () => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            envName
          }
        }
      }
    `
    )

    const envName = site.siteMetadata?.envName;
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-left">
            <h4>Copyright</h4>
            <img src={footerLogo} className="footer-logo" alt="" />
            <p>Copyright 2020 Vishay Precision Group, Inc.</p>
            <p>All rights reserved.</p>
              <p>
                  <a href="https://vpgonboard.com/terms_conditions">Terms and Conditions</a>
              </p>
            <a href="http://www.vpgsensors.com/legal">Privacy and Legal</a>
          </div>
          <div className="col-md-4 footer-center">
            <h4>Navigation</h4>
              <Menus />
          </div>
          <div className="col-md-4 footer-right">
            <h4>Contact US</h4>
            <ul>
              {
                envName === 'si' ? (
                  <>
                    <li className="footer-address">5920 South 194th Street | Kent, WA 98032 | USA</li>
                    <li className="footer-phone">Sales Support: 800-638-5111</li>
                    <li className="footer-phone-mob">Technical Support: 626-202-5047</li>
                    <li className="footer-email"><a href="mailto:sionboard@vpgsensors.com">sionboard@vpgsensors.com</a></li>
                  </>
                ) : (
                  <>
                    <li>VPG Systems UK, Ltd.</li>
                    <li className="footer-address">Airedale House, Canal Road, Bradford BD2 1AG United Kingdom</li>
                    <li className="footer-phone">TEL: +44 (0) 1274 771177</li>
                    <li className="footer-phone-mob">FAX: +44 (0) 1274 781178</li>
                    <li className="footer-email"><a href="mailto:onboardweighing@vpgsensors.com">onboardweighing@vpgsensors.com</a></li>
                  </>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
