import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {


  const data = useStaticQuery(graphql`
    query {
      menu: allMenuItems(filter: {menu_name: {glob: "main"}, parent: {id: {eq: null}}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            parent {
              id
            }
         }
       }
    } 
  }`);

    const items = data.menu.edges.map((item, i) => {
      return (
              <li><a className="dropbtn" key={i} href={item.node.url}>{item.node.title}</a></li>
      )
    });

  return  <ul>{items}</ul>
}
