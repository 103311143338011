import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {


  const data = useStaticQuery(graphql`
    query {
      menu: allMenuItems(filter: {menu_name: {glob: "main"}, parent: {id: {eq: null}}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            parent {
              id
            }
         }
       }
    } 
      menu2: allMenuItems(filter: {menu_name: {glob: "main"}, parent: {id: {regex: ""}}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            parent {
              id
            }
         }
       }
    }
  }`);

    const showMenu = (e, data) => {

        let clicedEl = e.currentTarget.id.replace( "-btn", '-block');
        let element = document.getElementById(clicedEl);

        console.log(element)
        let elementBefor = document.querySelector(".dropdown-content-mobile:not(.d-none)");
        if (elementBefor) {
            elementBefor.classList.add("d-none");
            document.querySelector(".active").classList.remove("active");
        }

        document.getElementById(e.currentTarget.id).classList.add("active");
        if (element.classList.contains('menu-active')) {
            console.log("te");
            element.classList.remove("menu-active");
            document.getElementById(e.currentTarget.id).classList.remove('active');
            element.classList.add("d-none");
        } else {
            element.classList.remove("d-none");
            element.classList.add("menu-active");
            console.log("te22");
        }

    }

    const items = data.menu.edges.map((item, i) => {
      return (
          <div className="dropdown-mobile">
            <button className="dropbtn-mobile" id={item.node.title.toLowerCase() + "-btn"} onClick={showMenu} key={i}>{item.node.title}</button>
            <div className="dropdown-content-mobile d-none" id={item.node.title.toLowerCase() + "-block"}>
                <a className="link-mobile-first-lvl" key={i} href={item.node.url}>{item.node.title}</a>
                {data.menu2.edges.map((item2, i)=> {
                    if(item.node.id === item2.node.parent.id ){
                        return  <a key={i} href={item2.node.url}>{item2.node.title}</a>;
                    }
                })}
            </div>
          </div>
      )
    });

    return  items
}
