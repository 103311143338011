import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {


  const data = useStaticQuery(graphql`
    query {
      menu: allMenuItems(filter: {menu_name: {glob: "main"}, parent: {id: {eq: null}}}, sort: {order: ASC, fields: weight}) {
        edges {
          node {
            id
            menu_name
            url
            title
            weight
            parent {
              id
            }
         }
       }
    } 
      menu2: allMenuItems(filter: {menu_name: {glob: "main"}, parent: {id: {regex: ""}}}) {
        edges {
          node {
            id
            menu_name
            url
            title
            parent {
              id
            }
         }
       }
    }
  }`);

    const items = data.menu.edges.map((item, i) => {
      return (
          <div className="dropdown">
            <a className="dropbtn" key={i} href={item.node.url}>{item.node.title}</a>
            <div className="dropdown-content">
                {data.menu2.edges.map((item2, i)=> {
                    if(item.node.id === item2.node.parent.id ){
                        return  <a key={i} href={item2.node.url}>{item2.node.title}</a>;
                    }
                })}
            </div>
          </div>
      )
    });

    return  items
}
